import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
    Button, Box, useMediaQuery, AvatarGroup, Avatar, Stack, Typography, Divider, CircularProgress,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Modal, Backdrop, IconButton, TextField, ToggleButtonGroup, ToggleButton, InputAdornment,
    Autocomplete, Chip, Paper, Grid
} from '@mui/material'
import {
    Cancel, Save, Delete, Edit, ViewInAr, Search, Info, AddCircleOutline, Clear
} from '@mui/icons-material'
import { styled, useTheme } from '@mui/material/styles'

import { red, blue, grey, orange } from '@mui/material/colors'

import { APIMainRequestGET, APIMainRequestPOST, APIMainRequestDELETE, APIUrl } from '../Manager/CommManager'
import UploadFileLast from '../Components/UploadManyFiles'
import UpdateLasts from '../Components/UpdateLast'
import leftFoot from '../Assets/leftFoot.png'
import rightFoot from '../Assets/rightFoot.png'
import logo from '../Assets/logo.png'



export default function Last() {

    const [loading, setLoading] = React.useState(false)

    const [getAddNewLast, setAddNewLast] = React.useState(false)

    const [getLastList, setLastList] = useState([])

    const [getMerchantProductList, setMerchantProductList] = useState([])
    const [getLastFileList, setLastFileList] = useState([])
    const [getStandard, setStandard] = useState([])



    const [stdName, setStdName] = useState(null)
    const [stdSize, setStdSize] = useState(null)
    const [stdWidth, setStdWidth] = useState(null)

    const handleChangeStandard = (event, newStandard) => {
        console.log('Last -> handleChangeStandard -> newSide : ' + JSON.stringify(newStandard, null, 2))
        setStdName(newStandard.name)
        setStdSize(newStandard.size)
        setStdWidth(newStandard.width)
    }
    const [productFullSelected, setProductFullSelected] = useState({})
    const [productSelected, setProductSelected] = useState('')
    const [productNameSelected, setProductNameSelected] = useState('')

    const [lastIDSelected, setLastIDSelected] = useState('')
    const [lastSNSelected, setLastSNSelected] = useState('')

    const [getSide, setSide] = React.useState(null)
    const handleChangeLeftRight = (event, newSide) => {
        console.log('Last -> handleChangeLeftRight -> newSide : ' + newSide)
        setSide(newSide)
    }
    const [getFileName, setFileName] = React.useState(null)

    const [lastINDEXSelected, setLastINDEXSelected] = useState('')
    const [isEditMode, setIsEditMode] = useState(false)


    //////////////// RESEARCH PART //////////////////////
    // Original and filtered array states
    const [searchQuery, setSearchQuery] = useState('')
    const [filteredArray, setFilteredArray] = useState(getLastList)
    const [getOnlyLastList, setOnlyLastList] = useState([])

    function filterLastsBySearch(data, searchValue) {
        return data.map(item => ({
            ...item,
            lasts: item.lasts.filter(last =>
                Object.values(last).some(value =>
                    String(value).toLowerCase().includes(searchValue.toLowerCase())
                )
            )
        })).filter(item => item.lasts.length > 0) // Keep only items with matching lasts
    }

    // Search update function
    const handleSearch = (event) => {
        console.log('Last -> handleSearch -> ')
        const query = event.target.value.toLowerCase()
        setSearchQuery(query)

        if (query === '') {
            // If the search is empty, display the original table
            // setFilteredArray(getLastList)
            clearSearch(event)
        } else {
            // Alternatively, filter elements containing the search text
            const results = getLastList.filter((item) => searchInObject(item, query))
            const results1 = filterLastsBySearch(results, query)
            console.log('Last -> handleSearch -> results1 : ' + JSON.stringify(results1, null, 2))
            setOnlyLastList(results1)
            setFilteredArray(results)
        }
    }

    // Recursive search function for all levels
    const searchInObject = (obj, query) => {
        // Checks whether a field contains the required value
        for (let key in obj) {
            if (Array.isArray(obj[key])) {
                // If the value is an array, we check each element of the array
                if (obj[key].some((item) => searchInObject(item, query))) {
                    return true
                }
            } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                // If the value is an object, we search for it recursively
                if (searchInObject(obj[key], query)) {
                    return true
                }
            } else if (String(obj[key]).toLowerCase().includes(query)) {
                return true
            }
        }
        return false
    }

    const clearSearch = (e) => {
        setSearchQuery('')
        setFilteredArray(getLastList)
        setOnlyLastList([])
    }
    //////////////// RESEARCH PART //////////////////////


    const [storeData, setData] = useState({
        productID: '',
        id: '',
        sn: '',
        linkPly: '',
        standard: '',
        size: '',
        sizeWidth: '',
        side: '',
        brand: '',
        model: '',
        gender: '',
        type: '',
        lenght: '',
        width: '',
        ballBirth: '',
        heelBirth: '',
        heel: '',
        toeHeight: '',
        lastLenght: '',
        lastWidth: '',
        description: '',
        active: '',
    })

    //////////////// MODAL //////////////////////
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    //////////////// MODAL //////////////////////

    const [getLastSelected, setLastSelected] = useState({})
    //////////////// MODAL FORM LAST //////////////////////
    const [openFormLast, setOpenFormLast] = React.useState(false)
    const handleOpenFormLast = () => setOpenFormLast(true)
    const handleCloseFormLast = () => setOpenFormLast(false)
    //////////////// MODAL FORM LAST //////////////////////

    // Function to return a merchant product by ID
    const getMerchantProductById = (id) => {
        console.log('Last -> getMerchantProductById -> id : ' + id)
        console.log('Last -> getMerchantProductList -> : ' + getMerchantProductList)
        return getMerchantProductList.find(products => products.id === id)
    }

    //////////////// Alert View //////////////// 
    const [idLast, setIdLast] = useState(null)
    const [idProduct, setIdProduct] = useState(null)
    const [alertIsOpen, setAlertIsOpen] = useState(false)
    const [titleAlert, setTitleAlert] = useState('')
    const [messageAlert, setMessageAlert] = useState('')
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

    const handleOpenDialogAlert = (proID, lastID) => {

        setIdProduct(proID)
        setIdLast(lastID)
        setAlertIsOpen(true)
        setTitleAlert('Please confirme to delete')
        setMessageAlert(`Are you sure to delete the last ID : ${lastID} ?`)
    }

    const handleAlertCloseAgree = () => {
        console.log('DialogAlert -> handleAlertCloseAgree')

        lastDelete(idProduct, idLast)

        setAlertIsOpen(false)
    }

    const handleAlertCloseDisagree = () => {
        console.log('DialogAlert -> handleAlertCloseDisagree')
        setAlertIsOpen(false)
    }

    const DialogAlert = ({ title, message, cancelBtn, validateBtn }) => {
        return (
            <React.Fragment>
                <Dialog
                    fullScreen={fullScreen}
                    open={alertIsOpen}
                    onClose={handleAlertCloseDisagree}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title"> {title} </DialogTitle>
                    <DialogContent>
                        <DialogContentText> {message} </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color='error' autoFocus onClick={handleAlertCloseDisagree}> {cancelBtn} </Button>
                        <Button variant="outlined" onClick={handleAlertCloseAgree} autoFocus> {validateBtn} </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
    //////////////// Alert View //////////////// 


    const lastDelete = (productID, lastID) => {
        setLoading(true)
        console.log('Last -> standardDelete -> productID : ' + productID)
        console.log('Last -> standardDelete -> lastID : ' + lastID)

        const merchantID = localStorage.getItem('merchantID')
        const settingsParams = {
            merchantID: merchantID,
            productID: productID,
            lastID: lastID
        }

        console.log('Last -> standardDelete -> settingsParams : ' + JSON.stringify(settingsParams, null, 2))
        const makeRequest = `/lasts/delete/${merchantID}/${productID}/${lastID}`
        APIMainRequestDELETE(makeRequest, settingsParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('Last -> standardDelete : ' + JSON.stringify(myJson, null, 2))

            setLastList(myJson)
            toggleGroup(lastINDEXSelected, productSelected, getLastSelected)
            setSearchQuery('')
            setFilteredArray(myJson)
            setOnlyLastList([])

        }).finally(() => {
            console.log('Last -> standardDelete -> Finally : ')

            setIdProduct(null)
            setIdLast(null)
            setLoading(false)

        })

    }

    const lastAdd = (bodyParams) => {
        setLoading(true)
        console.log('Last -> standardAdd -> ')

        console.log('Last -> standardAdd -> settingsParams : ' + JSON.stringify(bodyParams, null, 2))
        const makeRequest = `/lasts/add`
        APIMainRequestPOST(makeRequest, bodyParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('Last -> standardAdd : ' + JSON.stringify(myJson, null, 2))

            setLastList(myJson)
            setSearchQuery('')
            setFilteredArray(myJson)
            setOnlyLastList([])

        }).finally(() => {
            console.log('Last -> standardAdd -> Finally : ')
            //toggleAddNewLast()
            // setLoading(false)
        })

    }

    const lastList = () => {
        console.log('Last -> LastListRequest -> ')
        setLoading(true)
        const merchantID = localStorage.getItem('merchantID')
        const settingsParams = {
            value: 0
        }
        console.log('Last -> LastListRequest -> settingsParams : ' + JSON.stringify(settingsParams, null, 2))
        const makeRequest = `/lasts/list/${merchantID}`
        APIMainRequestGET(makeRequest, settingsParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('Last -> LastListRequest : ' + JSON.stringify(myJson, null, 2))

            setLastList(myJson)
            setFilteredArray(myJson)
            setEnabledGroups(myJson.map(() => false))


        }).finally(() => {
            console.log('Last -> LastListRequest -> Finally : ')
            setLoading(false)
        })

    }

    const merchantProductList = () => {
        console.log('Last -> merchantProductList -> ')
        setLoading(true)
        const getToken = localStorage.getItem('localAccessToken') //getData('localAccessToken')
        const getMerchantShop = localStorage.getItem('localShop') //getData('localShop')

        if (getToken !== null) {

            console.log('Last -> merchantProductList -> Get getMerchantShop : ' + getMerchantShop)
            console.log('Last -> merchantProductList -> Get getToken : ' + getToken)
            const makeRequest = `${APIUrl}/shopify/products/?setToken=${getToken}&setShop=${getMerchantShop}`

            const settingsParams = { perPage: 0 }

            APIMainRequestGET(makeRequest, settingsParams).then(function (response) {
                return response.getData
            }).then(function (myJson) {
                console.log('Last -> merchantProductList -> getProductsList : ' + JSON.stringify(myJson.products, null, 2))
                setMerchantProductList(myJson.products)

            }).finally(() => {
                console.log('Last -> merchantProductList -> getProductsList -> Finally ->')
                setLoading(false)
            })
        }

    }

    

    const lastFileList = () => {
        console.log('Last -> lastFileList -> ')
        setLoading(true)
        const merchantID = localStorage.getItem('merchantID')
        const settingsParams = {
            value: 0
        }
        console.log('Last -> lastFileList -> settingsParams : ' + JSON.stringify(settingsParams, null, 2))
        const makeRequest = `/last/filelist/${merchantID}`
        APIMainRequestGET(makeRequest, settingsParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            // console.log('Last -> lastFileList : ' + JSON.stringify(myJson, null, 2))

            const addButton = <>
                <Button variant="text" startIcon={<AddCircleOutline />}
                    onClick={handleOpen}>
                    Last files
                </Button>
            </>

            const tmpList = []
            tmpList.push(addButton)
            for (let index = 0; index < myJson.length; index++) {
                const element = myJson[index]

                tmpList.push(element)
            }

            setLastFileList(tmpList)

        }).finally(() => {
            console.log('Last -> lastFileList -> Finally : ')
            setLoading(false)

        })

    }

    const getAllStandard = () => {
        setLoading(true)
        const settingsParams = {
            perPage: 0,
        }
        const merchantID = localStorage.getItem('merchantID')
        const makeRequest = `/lcs/std/${merchantID}`
        APIMainRequestGET(makeRequest, settingsParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            // console.log('Last -> getStandardList  : ' + JSON.stringify(myJson, null, 2))

            setStandard(myJson)

        }).finally(() => {
            console.log('Last -> getStandardList -> Finally : ')
            setLoading(false)
        })
    }

    const closeModalRefreshList = () => {
        console.log('Last -> closeModalRefreshList - ')
        toggleGroup(lastINDEXSelected, productSelected, getLastSelected)
        lastList()
        lastFileList()
        handleClose()
        handleCloseFormLast()
    }

    const [enabledGroups, setEnabledGroups] = useState([])

    const toggleGroup = (index, item, productID) => {
        console.log('Last -> toggleGroup -> index : ' + index)
        console.log('Last -> toggleGroup -> productID : ' + productID.id)
        console.log('Last -> toggleGroup -> item : ' + JSON.stringify(item, null, 2))
        setIsEditMode(!isEditMode)

        setEnabledGroups((prevEnabledGroups) =>
            prevEnabledGroups.map((isEnabled, i) =>
                i === index ? !isEnabled : isEnabled
            )
        )

        if (!isEditMode) {
            setProductFullSelected(productID)
            setProductNameSelected(productID.title)
            setProductSelected(productID.id)
            setLastSelected(item)
            setLastINDEXSelected(index)
        } else {
            setLastSelected({})
            setProductFullSelected({})
            setProductNameSelected('')
            setLastINDEXSelected('')
            setProductSelected('')
            setLastIDSelected('')
            setLastSNSelected('')
        }
    }

    useEffect(() => {
        console.log('Last -> useEffect')
        const merchantID = localStorage.getItem('merchantID')
        lastList()
        merchantProductList()
        lastFileList()
        getAllStandard()
    }, [])

    const { register, setValue, handleSubmit, control, reset, formState: { errors } } = useForm()

    const [isErrorsLenght, setErrorsLenght] = useState(false)
    const [isErrorsWidth, setErrorsWidth] = useState(false)
    const [isErrorsBallGirth, setErrorsBallgirth] = useState(false)
    const [isErrorsHeelGirth, setErrorsHeelGirth] = useState(false)
    const [isErrorsHeel, setErrorsHeel] = useState(false)
    const [isErrorsToeHeight, setErrorsToeHeight] = useState(false)
    const [isErrorsLastLenght, setErrorsLastLenght] = useState(false)
    const [isErrorsLastWidth, setErrorsLastWidth] = useState(false)

    const ErrorTextField = styled(TextField)(({ theme, error }) => ({
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: error ? theme.palette.error.main : theme.palette.primary.main,
            },
            "&:hover fieldset": {
                borderColor: error ? theme.palette.error.dark : theme.palette.primary.dark,
            },
            "&.Mui-focused fieldset": {
                borderColor: error ? theme.palette.error.dark : theme.palette.primary.dark,
            },
        },
    }))
    const validateDecimal = (value) => /^\d*\.?\d*$/.test(value)
    const handleKeyLenght = (event) => {
        console.log('Last -> handleKey -> Value : ' + event.target.value)
        if (validateDecimal(event.target.value)) { setErrorsLenght(false) } else { setErrorsLenght(true) }
    }
    const handleKeyWidth = (event) => {
        console.log('Last -> handleKey -> Value : ' + event.target.value)
        if (validateDecimal(event.target.value)) { setErrorsWidth(false) } else { setErrorsWidth(true) }
    }
    const handleKeyBallgirth = (event) => {
        console.log('Last -> handleKey -> Value : ' + event.target.value)
        if (validateDecimal(event.target.value)) { setErrorsBallgirth(false) } else { setErrorsBallgirth(true) }
    }
    const handleKeyHeelGirth = (event) => {
        console.log('Last -> handleKey -> Value : ' + event.target.value)
        if (validateDecimal(event.target.value)) { setErrorsHeelGirth(false) } else { setErrorsHeelGirth(true) }
    }
    const handleKeyHeel = (event) => {
        console.log('Last -> handleKey -> Value : ' + event.target.value)
        if (validateDecimal(event.target.value)) { setErrorsHeel(false) } else { setErrorsHeel(true) }
    }
    const handleKeyToeHeight = (event) => {
        console.log('Last -> handleKey -> Value : ' + event.target.value)
        if (validateDecimal(event.target.value)) { setErrorsToeHeight(false) } else { setErrorsToeHeight(true) }
    }
    const handleKeyLastLenght = (event) => {
        console.log('Last -> handleKey -> Value : ' + event.target.value)
        if (validateDecimal(event.target.value)) { setErrorsLastLenght(false) } else { setErrorsLastLenght(true) }
    }
    const handleKeyLastWidth = (event) => {
        console.log('Last -> handleKey -> Value : ' + event.target.value)
        if (validateDecimal(event.target.value)) { setErrorsLastWidth(false) } else { setErrorsLastWidth(true) }
    }

    ////////////////////////////////// SECTION TO AD A NEW LAST //////////////////////////////

    const [newProdID, setNewProdID] = useState(null)
    const [newProdTitle, setNewProdTitle] = useState(null)
    const [newProdImage, setNewProdImage] = useState(null)

    const handleSelectProd = (event, newProd) => {
        console.log('Last -> handleSelectProd -> newSide : ' + JSON.stringify(newProd, null, 2))
        setNewProdID(newProd.id)
        setNewProdTitle(newProd.title)
        // setNewProdImage(newProd.image.src)
    }

    const toggleAddNewLast = () => {
        console.log('Last -> toggleAddNewLast -> getAddNewLast : ' + getAddNewLast)
        if (getAddNewLast === true) {
            setStdName(null)
            setStdSize(null)
            setStdWidth(null)
            setSide(null)
            setFileName(null)
            setNewProdID(null)
            setNewProdTitle(null)
        } else {

        }
        setAddNewLast(!getAddNewLast)
    }

    const onSubmitNewLast = (formData) => {
        const merchantID = localStorage.getItem('merchantID')
        console.log('Last -> onSubmitNewLast -> merchantID : ' + merchantID)
        console.log('Last -> onSubmitNewLast -> productID : ' + newProdID)
        console.log('Last -> onSubmitNewLast -> brand : ' + formData.brand)
        console.log('Last -> onSubmitNewLast -> getDescription : ' + formData.model)

        const setupParams = {
            merchantID: merchantID,
            productID: newProdID,
            name: productNameSelected,
            linkPly: getFileName,
            standard: stdName,
            size: stdSize,
            sizeWidth: stdWidth,
            side: getSide,
            brand: formData.brand != undefined ? formData.brand : storeData.brand,
            model: formData.model != undefined ? formData.model : storeData.model,
            gender: formData.gender != undefined ? formData.gender : storeData.gender,
            type: formData.type != undefined ? formData.type : storeData.type,
            lenght: formData.lenght != undefined ? formData.lenght : storeData.lenght,
            width: formData.width != undefined ? formData.width : storeData.width,
            ballBirth: formData.ballGirth != undefined ? formData.ballGirth : storeData.ballBirth,
            heelBirth: formData.heelGirth != undefined ? formData.heelGirth : storeData.heelBirth,
            heel: formData.heel != undefined ? formData.heel : storeData.heel,
            toeHeight: formData.toeHeight != undefined ? formData.toeHeight : storeData.toeHeight,
            lastLenght: formData.lastLenght != undefined ? formData.lastLenght : storeData.lastLenght,
            lastWidth: formData.lastWidth != undefined ? formData.lastWidth : storeData.lastWidth,
            description: formData.description != undefined ? formData.description : storeData.description,
            active: true,
        }
        console.log('Last -> onSubmitNewLast -> setupParams : ' + JSON.stringify(setupParams, null, 2))
        lastAdd(setupParams)

        setTimeout(() => {
            toggleAddNewLast()
            setLoading(false)
        }, 2000)


    }

    const FileListAddNewLast = () => {
        console.log('Last -> FileListAddNewLast -> ')

        return (
            <Autocomplete
                onChange={(event, newValue) => {
                    setFileName(newValue)
                }}
                id="controllable-states-demo"
                options={getLastFileList}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Select a 3D File" />}
            />
        )
    }

    const DisplayStandardListAddNewLast = () => {
        console.log('Last -> DisplayStandardListAddNewLast')
        const options = getStandard.map((option) => {
            const firstLetter = option.name[0].toUpperCase()
            return {
                firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                ...option,
            }
        })
        return (
            <>
                <Stack direction="column" spacing={2}>

                    <Autocomplete
                        onChange={(event, newValue) => {
                            handleChangeStandard(event, newValue)
                        }}
                        options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                        groupBy={(option) => option.firstLetter}
                        getOptionLabel={(option) => `Name : ${option.name} - Size : ${option.size} - Width : ${option.width}`}
                        sx={{ width: '20vw' }}
                        renderInput={(params) => <TextField {...params} label="Select standard size" />}

                    />
                    <Stack direction="row" spacing={2}>
                        <Typography variant="body1" gutterBottom><b>Name : </b>{stdName != null ? stdName : '-'}</Typography>
                        <Typography variant="body1" gutterBottom><b>Size : </b>{stdSize != null ? stdSize : '-'}</Typography>
                        <Typography variant="body1" gutterBottom><b>Width : </b>{stdWidth != null ? stdWidth : '-'}</Typography>
                    </Stack>

                </Stack>
            </>
        )
    }

    const DisplayProductList = () => {
        console.log('Last -> DisplayProductList -> ')
        const options = getMerchantProductList.map((option) => {
            const firstLetter = option.title[0].toUpperCase()
            return {
                firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                ...option,
            }
        })
        return (
            <>
                <Stack direction="column" spacing={2}>

                    <Autocomplete
                        onChange={(event, newValue) => {
                            handleSelectProd(event, newValue)
                        }}
                        options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                        groupBy={(option) => option.firstLetter}
                        getOptionLabel={(option) => `ID : ${option.id} - Title : ${option.title}`}
                        sx={{ width: '20vw' }}
                        renderInput={(params) => <TextField {...params} label="Select a product" />}

                    />
                    <Stack direction="row" spacing={2}>
                        <Typography variant="body1" gutterBottom><b>ID : </b>{newProdID != null ? newProdID : '-'}</Typography>
                        <Typography variant="body1" gutterBottom><b>Title : </b>{newProdTitle != null ? newProdTitle : '-'}</Typography>
                        {/* <Typography variant="body1" gutterBottom><b>newProdImage : </b>{newProdImage != null ? newProdImage : '-'}</Typography> */}
                    </Stack>

                </Stack>
            </>
        )
    }

    const DisplayAddNewLast = () => {

        return (
            <Box sx={{
                width: '100%', '& .actions': { color: 'text.secondary', },
                '& .textPrimary': { color: 'text.primary', }
            }} >

                <form onSubmit={handleSubmit(onSubmitNewLast)}>
                    <Stack direction="column" spacing={2} sx={{ background: '#ffffff', padding: 2 }}>

                        <Stack direction="row" spacing={2}>

                            <DisplayProductList />

                            <ToggleButtonGroup
                                value={getSide}
                                sx={{ color: grey[800] }}
                                onChange={handleChangeLeftRight}
                                exclusive aria-label="Platform">
                                <ToggleButton value="left">
                                    <Avatar alt="Remy Sharp" src={leftFoot} />
                                    <Typography> Left </Typography>
                                </ToggleButton>
                                <ToggleButton value="right">
                                    <Typography> Right </Typography>
                                    <Avatar alt="Remy Sharp" src={rightFoot} />
                                </ToggleButton>
                            </ToggleButtonGroup>

                            <Stack direction="column" spacing={2}>

                                <FileListAddNewLast />

                                <Stack direction="row" spacing={1} margin={1} >
                                    <Avatar sx={{ bgcolor: grey[500], width: '2vw', height: '2vw' }}>
                                        <ViewInAr />
                                    </Avatar>
                                    <Typography>{getFileName != null ? getFileName : '-'}</Typography>
                                </Stack>

                            </Stack>

                            <DisplayStandardListAddNewLast />

                        </Stack>

                        <Stack direction="row" spacing={1} >
                            <Controller name="brand" control={control} render={({ field: { onChange, onBlur, value } }) => (<>
                                <TextField id="brand" label="Brand" sx={{ width: '100%' }} onBlur={onBlur} onChange={onChange} />
                            </>)} />
                            <Controller name="model" control={control} render={({ field: { onChange, onBlur, value } }) => (<>
                                <TextField id="model" label="Model" sx={{ width: '100%' }} onBlur={onBlur} onChange={onChange} />
                            </>)} />
                            <Controller name="gender" control={control} render={({ field: { onChange, onBlur, value } }) => (<>
                                <TextField id="gender" label="Gender" sx={{ width: '100%' }} onBlur={onBlur} onChange={onChange} />
                            </>)} />
                            <Controller name="type" control={control} render={({ field: { onChange, onBlur, value } }) => (<>
                                <TextField id="type" label="Type" sx={{ width: '100%' }} onBlur={onBlur} onChange={onChange} />
                            </>)} />
                        </Stack>

                        <Stack direction="row" spacing={1} >
                            <Controller name="lenght" control={control} render={({ field: { onChange, onBlur, value } }) => (<>
                                <ErrorTextField name='lenght' id="lenght" label="Foot Length" sx={{ width: '100%' }} onBlur={onBlur}
                                    onChange={onChange} inputProps={{ inputMode: "decimal" }} onKeyUp={handleKeyLenght}
                                    error={isErrorsLenght} helperText={isErrorsLenght ? "Please enter a valid number" : ""}
                                />
                            </>)} />
                            <Controller name="width" control={control} render={({ field: { onChange, onBlur, value } }) => (<>
                                <ErrorTextField name='width' id="width" label="Foot Width" sx={{ width: '100%' }} onBlur={onBlur}
                                    onChange={onChange} inputProps={{ inputMode: "decimal" }} onKeyUp={handleKeyWidth}
                                    error={isErrorsWidth} helperText={isErrorsWidth ? "Please enter a valid number" : ""}
                                />
                            </>)} />
                            <Controller name="ballGirth" control={control} render={({ field: { onChange, onBlur, value } }) => (<>
                                <ErrorTextField name='ballGirth' id="ballGirth" label="Last Ball Girth" sx={{ width: '100%' }}
                                    onBlur={onBlur} onChange={onChange} inputProps={{ inputMode: "decimal" }} onKeyUp={handleKeyBallgirth}
                                    error={isErrorsBallGirth} helperText={isErrorsBallGirth ? "Please enter a valid number" : ""}
                                />
                            </>)} />
                            <Controller name="heelGirth" control={control} render={({ field: { onChange, onBlur, value } }) => (<>
                                <ErrorTextField name='heelGirth' id="heelGirth" label="Last Heel Girth" sx={{ width: '100%' }}
                                    onBlur={onBlur} onChange={onChange} inputProps={{ inputMode: "decimal" }} onKeyUp={handleKeyHeelGirth}
                                    error={isErrorsHeelGirth} helperText={isErrorsHeelGirth ? "Please enter a valid number" : ""}
                                />
                            </>)} />
                        </Stack>

                        <Stack direction="row" spacing={1} >
                            <Controller name="heel" control={control} render={({ field: { onChange, onBlur, value } }) => (<>
                                <ErrorTextField name='heel' id="heel" label="Last Heel Height" sx={{ width: '100%' }} onBlur={onBlur}
                                    onChange={onChange} inputProps={{ inputMode: "decimal" }} onKeyUp={handleKeyHeel}
                                    error={isErrorsHeel} helperText={isErrorsHeel ? "Please enter a valid number" : ""}
                                />
                            </>)} />
                            <Controller name="toeHeight" control={control} render={({ field: { onChange, onBlur, value } }) => (<>
                                <ErrorTextField name='toeHeight' id="toeHeight" label="Last Toe Height" sx={{ width: '100%' }}
                                    onBlur={onBlur} onChange={onChange} inputProps={{ inputMode: "decimal" }} onKeyUp={handleKeyToeHeight}
                                    error={isErrorsToeHeight} helperText={isErrorsToeHeight ? "Please enter a valid number" : ""}
                                />
                            </>)} />
                            <Controller name="lastLenght" control={control} render={({ field: { onChange, onBlur, value } }) => (<>
                                <ErrorTextField name='lastLenght' id="lastLenght" label="Last Length" sx={{ width: '100%' }}
                                    onBlur={onBlur} onChange={onChange} inputProps={{ inputMode: "decimal" }} onKeyUp={handleKeyLastLenght}
                                    error={isErrorsLastLenght} helperText={isErrorsLastLenght ? "Please enter a valid number" : ""}
                                />
                            </>)} />
                            <Controller name="lastWidth" control={control} render={({ field: { onChange, onBlur, value } }) => (<>
                                <ErrorTextField name='lastWidth' id="lastWidth" label="Last Width" sx={{ width: '100%' }}
                                    onBlur={onBlur} onChange={onChange} inputProps={{ inputMode: "decimal" }} onKeyUp={handleKeyLastWidth}
                                    error={isErrorsLastWidth} helperText={isErrorsLastWidth ? "Please enter a valid number" : ""}
                                />
                            </>)} />
                        </Stack>

                        <Stack direction="row" spacing={1} >
                            <Controller name="description" control={control} render={({ field: { onChange, onBlur, value } }) => (<>
                                <TextField id="description" label="Description" multiline rows={4} sx={{ width: '100%' }} onBlur={onBlur} onChange={onChange} />
                            </>)} />
                        </Stack>


                        <Stack direction="row" spacing={1} >
                            <Button variant="outlined" startIcon={<Cancel />} sx={{ color: grey[500] }} onClick={() => { toggleAddNewLast() }}>
                                Cancel
                            </Button>
                            <Button type="submit" variant="outlined" startIcon={<Save />} sx={{ color: blue[500] }}>
                                Save
                            </Button>
                        </Stack>

                    </Stack>
                </form>

            </Box>
        )

    }

    const makeACopy = (index, item, productID) => {

        console.log('Last -> makeACopy -> item : ' + JSON.stringify(item, null, 2))
        const merchantID = localStorage.getItem('merchantID')
        console.log('Last -> makeACopy -> merchantID : ' + merchantID)
        console.log('Last -> makeACopy -> productID : ' + productSelected)
        console.log('Last -> makeACopy -> brand : ' + item.brand)
        console.log('Last -> makeACopy -> getDescription : ' + item.model)
        const getFileName = item.linkPly.split('/').pop()
        const setupParams = {
            merchantID: merchantID,
            productID: productSelected,
            name: productNameSelected,
            linkPly: getFileName,
            standard: item.standard,
            size: item.size,
            sizeWidth: item.sizeWidth,
            side: item.side,
            brand: item.brand,
            model: item.model,
            gender: item.gender,
            type: item.type,
            lenght: item.lenght,
            width: item.width,
            ballBirth: item.ballBirth,
            heelBirth: item.heelBirth,
            heel: item.heel,
            toeHeight: item.toeHeight,
            lastLenght: item.lastLenght,
            lastWidth: item.lastWidth,
            description: item.description,
            active: true,
        }
        console.log('Last -> makeACopy -> setupParams : ' + JSON.stringify(setupParams, null, 2))
        lastAdd(setupParams)

        setTimeout(() => {
            setLoading(false)
        }, 2000)

    }

    ////////////////////////////////// SECTION TO AD A NEW LAST //////////////////////////////


    const returnLastShortInformations = (index, item, prod) => {
        return (
            <Stack direction="column" spacing={1} sx={{ borderColor: item.side === 'left' ? red[700] : blue[700], borderStyle: 'solid', borderWidth: 2, padding: 1, borderRadius: 1, margin: 2, }} >
                <Stack direction="row" spacing={1} >
                    <Typography id="modal-modal-title" variant="body1"><b>File name :</b> {item.linkPly.split('/').pop()}</Typography>
                    <Typography id="modal-modal-title" variant="body1"><b>S/N :</b> {item.sn}</Typography>
                </Stack>

                <Stack direction="row" spacing={1} >
                    <Typography id="modal-modal-title" variant="body1"><b>Standard :</b> {item.standard}</Typography>
                    <Typography id="modal-modal-title" variant="body1"><b>Size :</b> {item.size}</Typography>
                    <Typography id="modal-modal-title" variant="body1"><b>Width :</b> {item.sizeWidth}</Typography>
                </Stack>
                <Stack direction="row" spacing={2}>
                    {
                        isEditMode == true && lastINDEXSelected === index ?
                            <>
                                <Button variant="outlined" startIcon={<Cancel />} sx={{ color: grey[500] }} onClick={() => { toggleGroup(index, item, prod) }}>
                                    Cancel
                                </Button>

                                <Button variant="outlined" color='error' startIcon={<Delete />} sx={{ color: red[500] }} onClick={() => { handleOpenDialogAlert(prod.id, item.id) }}>
                                    Delete
                                </Button>

                                <Button variant="outlined" startIcon={<Cancel />} sx={{ color: orange[500] }} onClick={() => { makeACopy(index, item, prod.id) }}>
                                    Make a copy
                                </Button>

                                <Button variant="outlined" startIcon={<Info />} sx={{ color: blue[500] }} onClick={() => { handleOpenFormLast() }}>
                                    Details
                                </Button>
                            </> :
                            <>
                                <Button variant="outlined" startIcon={<Edit />} sx={{ color: blue[500] }} onClick={() => { toggleGroup(index, item, prod) }}>
                                    Edit
                                </Button>

                            </>
                    }
                </Stack>
            </Stack>
        )
    }

    const displayLastNumber = (last) => {

        return (
            <>
                <Stack direction="column" spacing={1} >
                    <Stack direction="row" spacing={1} >
                        <Typography id="modal-modal-title" variant="body1"><b>Left side :</b> </Typography>
                        <AvatarGroup max={10} spacing="medium">
                            {
                                last.map((itemLast, index) => (
                                    itemLast.side === 'left' ?
                                        <>
                                            <Avatar sx={{ bgcolor: red[700] }}>{itemLast.sn.slice(0, 2)}</Avatar>
                                        </>
                                        : null
                                ))
                            }
                        </AvatarGroup>
                    </Stack>
                    <Stack direction="row" spacing={1} >
                        <Typography id="modal-modal-title" variant="body1"><b>Right side :</b> </Typography>
                        <AvatarGroup max={10} spacing="medium">
                            {
                                last.map((itemLast, index) => (
                                    itemLast.side === 'right' ?
                                        <>
                                            <Avatar sx={{ bgcolor: blue[700] }}>{itemLast.sn.slice(0, 2)}</Avatar>
                                        </>
                                        : null
                                ))
                            }
                        </AvatarGroup>
                    </Stack>
                </Stack>
            </>
        )
    }
    const hasSrcValue = (obj) => obj?.image?.src;
    const returnImage = (merchantProd) => {
        if (hasSrcValue(merchantProd)) {
            return (
                <>
                    <Avatar
                        sx={{ width: '4vw', height: '4vw' }}
                        src={merchantProd.image.src}
                    />
                </>
            )
        } else {
            return (
                <>
                    <Avatar
                        sx={{ width: '4vw', height: '4vw' }}
                        src={logo}
                    />
                </>
            )
        }
    }
    const DisplayProduct = (productID, lastInfo) => {
        const trsID = Number(productID)
        const merchantProd = getMerchantProductById(trsID)
        return (
            <Stack direction="column" spacing={1} sx={{ width: '100%' }} >

                <Stack direction="row" spacing={1} >
                    {

                        merchantProd != undefined ?
                        returnImage(merchantProd) : <Avatar><CircularProgress /></Avatar>
                    }
                    <Stack direction="column" spacing={1} >
                        <Typography id="modal-modal-title" variant="body1"><b>{merchantProd != undefined ? merchantProd.title : 'Loading...'}</b></Typography>
                        <Typography variant="caption" sx={{ marginBottom: 1 }}>{merchantProd != undefined ? merchantProd.id : '-'}</Typography>
                    </Stack>

                    <Divider orientation="vertical" flexItem spacing={3} />

                    <Stack direction="column" spacing={1} >
                        {displayLastNumber(lastInfo.lasts)}
                    </Stack>
                </Stack>

                <Divider orientation="horizontal" flexItem />

                <Stack direction="row" spacing={1} >
                    <Stack direction="column" spacing={1} sx={{ width: '50%' }} >
                        {
                            lastInfo.lasts
                                .sort((a, b) => a.size - b.size)
                                .map((itemLast, index) => (
                                    itemLast.side === 'left' ?
                                        returnLastShortInformations(index, itemLast, merchantProd) : <></>
                                ))
                        }
                    </Stack>
                    <Divider orientation="vertical" flexItem />
                    <Stack direction="column" spacing={1} sx={{ width: '50%' }} >
                        {
                            lastInfo.lasts
                                .sort((a, b) => a.size - b.size)
                                .map((itemLast, index) => (
                                    itemLast.side === 'right' ?
                                        returnLastShortInformations(index, itemLast, merchantProd) : <></>
                                ))
                        }
                    </Stack>
                </Stack>
            </Stack>

        )
    }

    return (
        <Box
            sx={{
                width: '100%', '& .actions': { color: 'text.secondary', },
                '& .textPrimary': { color: 'text.primary', }
            }}
        >
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading} >
                <CircularProgress size={50} sx={{ color: grey[50] }} />
            </Backdrop>
            <DialogAlert title={titleAlert} message={messageAlert} cancelBtn={'Cancel'} validateBtn={'Confirm'} />
            <Stack direction="column" spacing={2} margin={2} >
                {

                    getAddNewLast === true ?
                        <>
                            <Button variant="outlined" size="large"
                                color="error"
                                startIcon={<Cancel />}
                                sx={{ width: '20vw' }}
                                onClick={toggleAddNewLast}>
                                Cancel
                            </Button>
                            <Divider>
                                <Chip label="ADD A NEW LAST" size="larg" sx={{ color: blue[800], background: blue[200] }} />
                            </Divider>

                            <DisplayAddNewLast />
                        </>
                        :
                        <>
                            <Button variant="outlined" size="large"
                                color="primary"
                                startIcon={<AddCircleOutline />}
                                sx={{ width: '20vw' }}
                                onClick={toggleAddNewLast}>
                                Add new Last
                            </Button>

                            <Divider>
                                <Chip label="LAST LIST" size="larg" sx={{ color: grey[800], background: grey[200] }} />
                            </Divider>
                            <Stack direction="row" spacing={2} margin={2} >
                                <TextField
                                    label="Search..."
                                    variant="outlined"
                                    value={searchQuery}
                                    onChange={handleSearch}
                                    margin="normal"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search />
                                            </InputAdornment>
                                        ),
                                        endAdornment: searchQuery && (
                                            <InputAdornment position="end">
                                                <IconButton onClick={clearSearch} size="small">
                                                    <Clear />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Stack>
                        </>
                }

                {
                    getOnlyLastList.length > 0 ?
                        getOnlyLastList.map((item, index) => (
                            <>
                                <Stack direction='column' spacing={2} sx={{ width: '100%', margin: 2 }}>
                                    {
                                        DisplayProduct(item.id, item)
                                    }
                                </Stack>
                            </>
                        )) :
                        filteredArray.map((item, index) => (
                            <>
                                <Stack direction='column' spacing={2} sx={{ width: '100%', margin: 2 }}>
                                    {
                                        DisplayProduct(item.id, item)
                                    }
                                </Stack>
                            </>
                        ))
                }
            </Stack>

            <Modal
                open={openFormLast}
                onClose={handleCloseFormLast}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute', top: '5vh', left: '10vw', width: '80vw', overflow: 'overlay',
                    height: '80vh', bgcolor: 'background.paper', borderRadius: 2, boxShadow: 24, p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Update this lasts
                    </Typography>

                    <UpdateLasts productID={productFullSelected} data={getLastSelected} closeModalRefreshList={closeModalRefreshList} />
                </Box>
            </Modal>


            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute', top: '5vh', left: '10vw', width: '80vw', overflow: 'overlay',
                    height: '80vh', bgcolor: 'background.paper', borderRadius: 2, boxShadow: 24, p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Upload your lasts
                    </Typography>

                    <UploadFileLast closeModalRefreshList={closeModalRefreshList} />
                </Box>
            </Modal>

        </Box>
    )
}
