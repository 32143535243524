import '../Styles/App.css';
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
    Gauge, gaugeClasses, LineChart, ChartContainer, ChartsReferenceLine, LinePlot,
    MarkPlot, ChartsXAxis, ChartsYAxis, SparkLineChart
} from '@mui/x-charts';
import { Box, Stack } from '@mui/material';

import { APIMainRequestGET, APIMainRequestPOST, APIMainRequestDELETE, APIUrl } from '../Manager/CommManager'

const Dashboard = ({ getWitchPage }) => {
    // const navigate = useNavigate();
    // const searchParams = useSearchParams();
    const [connected, setConnected] = useState(true)
    const [searchParams, setSearchParams] = useSearchParams();
    const [getAuthUrl, setAuthUrl] = useState()
    const UseQuery = () => new URLSearchParams(window.location.search)

    const [getLocalAccessToken, setLocalAccessToken] = useState('')
    const [getLocalShop, setLocalShop] = useState('')
    const [getLocalMerchantID, setLocalMerchantID] = useState('')

    const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
    const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
    const xLabels = [
        'Page A',
        'Page B',
        'Page C',
        'Page D',
        'Page E',
        'Page F',
        'Page G',
    ];

    const uDataWithRef = [3000, 2000, 1000, 1780, 890, 1390, 2490];
    const pDataWithRef = [1400, 398, 8800, 2908, 3800, 2800, 3300];
    const xLabelsWithRef = [
        'Page A',
        'Page B',
        'Page C',
        'Page D',
        'Page E',
        'Page F',
        'Page G',
    ];

    function SimpleLineChart() {
        return (
            <LineChart
                width={500}
                height={300}
                series={[
                    { data: pData, label: 'pv' },
                    { data: uData, label: 'uv' },
                ]}
                xAxis={[{ scaleType: 'point', data: xLabels }]}
            />
        );
    }

    function LineChartWithReferenceLines() {
        return (
            <ChartContainer
                width={500}
                height={300}
                series={[
                    { data: pDataWithRef, label: 'pv', type: 'line' },
                    { data: uDataWithRef, label: 'uv', type: 'line' },
                ]}
                xAxis={[{ scaleType: 'point', data: xLabelsWithRef }]}
                showHighlight
                showTooltip
            >
                <LinePlot />
                <MarkPlot />
                <ChartsReferenceLine
                    x="Page C"
                    label="Max PV PAGE"
                    lineStyle={{ stroke: 'red' }}
                />
                <ChartsReferenceLine y={9800} label="Max" lineStyle={{ stroke: 'red' }} />
                <ChartsXAxis />
                <ChartsYAxis />
            </ChartContainer>
        );
    }

    function AreaSparkLine() {
        return (
            <Stack direction="column" sx={{ width: '100%' }}>
                <Box sx={{ flexGrow: 1 }}>
                    <SparkLineChart
                        data={[3, -10, -2, 5, 7, -2, 4, 6]}
                        height={200}
                        area
                        showHighlight
                        showTooltip />
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                    <SparkLineChart
                        data={[3, -10, -2, 5, 7, -2, 4, 6]}
                        height={150}
                        curve="natural"
                        area
                        showHighlight
                        showTooltip
                    />
                </Box>
            </Stack>
        );
    }

    function AreaGauge() {
        const valueMaxDisplay = 100
        const setValue = 57
        return (
            <Gauge
                width={200}
                height={200}
                value={setValue}
                valueMin={0}
                valueMax={100}
                startAngle={-110}
                endAngle={110}
                sx={{
                    [`& .${gaugeClasses.valueText}`]: {
                        fontSize: 30,
                        transform: 'translate(0px, 0px)',
                    },
                }}
                text={
                    ({ value, valueMax }) => `${setValue} / ${valueMaxDisplay}`
                } />
        )
    }

    const merchantProductList = () => {
        console.log('Dashboard -> merchantProductList -> ')
        
        const getToken = localStorage.getItem('localAccessToken') //getData('localAccessToken')
        const getMerchantShop = localStorage.getItem('localShop') //getData('localShop')
        const getMerchantID = localStorage.getItem('merchantID') //getData('localShop')

        if (getToken !== null) {

            console.log('Dashboard -> merchantProductList -> Get getMerchantShop : ' + getMerchantShop)
            console.log('Dashboard -> merchantProductList -> Get getToken : ' + getToken)
            const makeRequest = `${APIUrl}/merchants/product/autoupdate/${getMerchantID}?setToken=${getToken}&setShop=${getMerchantShop}`

            const settingsParams = { perPage: 0 }

            APIMainRequestGET(makeRequest, settingsParams).then(function (response) {
                return response.getData
            }).then(function (myJson) {
                console.log('Dashboard -> merchantProductList -> getProductsList : ' + JSON.stringify(myJson.products, null, 2))
                //updateProductsDB(myJson.products)

            }).finally(() => {
                console.log('Dashboard -> merchantProductList -> getProductsList -> Finally ->')
            })
        }
    }

    const updateProductsDB = (productsList) => {

    }

    useEffect(() => {
        
        merchantProductList()

    }, []);

    return (
        <Box>
            <Stack direction="column" sx={{ width: '100%' }}>
                <SimpleLineChart />
                <Stack direction="row" sx={{ width: '100%' }}>
                    <AreaGauge />
                    <AreaGauge />
                </Stack>
                <LineChartWithReferenceLines />
                <AreaSparkLine />
            </Stack>

        </Box>

    );
};

export default Dashboard;